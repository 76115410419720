import { Usuario, Miembro } from './auth.model';
import { SearchRequest } from '@azigrene/searchrequest';
import {Periodicidad} from "@shared/enum/enums";
import {Frecuencia} from "@shared/utils";
import {EtiquetasArray} from '@shared/models/etiqueta.model';

export interface MiembroUsuario {
  id: number;
  email: string;
  rolId: number;
  contratoId: number;
  miembroId: number;
}

export interface MiembrosArray {
  commaSeparated: string;
  array: string[];
  jsonArray: MiembroUsuario[];
}

export interface PlantillaContrato {
  id: number;
  nombre: string;
  tipoContratoId: number;
  datos: {
    etiquetas: { nombre: string, descripcion: string }[];
    elementosActuacion: { nombre: string }[];
    entregables: { nombre: string, descripcion: string }[];
  };
}

export interface TareaPredefinida {
  nombre: string;
  plantillaContratoId: number;
  datos: {
    tarea: FormTarea;
  };
  plantillaContrato: PlantillaContrato;
  tipoContrato: TipoContrato;
}

export interface Contratante {
  id: number;
  nombre: string;
  logoUrl: string;
}

export interface AsistenciaTecnica {
  id: number;
  nombre: string;
}

export interface Contrato {
  id: number;
  nombre: string;
  adjudicatario: Adjudicatario;
  adjudicatarioId: number;
  contratante: Contratante;
  contratanteId: number;
  asistenciaTecnica: AsistenciaTecnica;
  asistenciaTecnicaId: number;
  descripcion: string;
  otrosDatos: any;
  presupuestoAnual: number;
  tipoContrato: TipoContrato;
  tipoContratoId: number;
  fechaInicioContrato: Date;
  fechaFinContrato: Date;
  numTareas: number;
  plantillaContratoId: number;

  miembrosArray: MiembrosArray;
  miembro: Miembro;
}

export interface Adjudicatario {
  id: number;
  nombre: string;
  cif: string;
}

export interface TipoContrato {
  id: number;
  tipo: string;
  esquemaEstados: string[];
}

export interface DocumentoTarea {
  id: number;
  documentoId: number;
  tareaId: number;
  observaciones: string;
  documento: Documento;
  contrato: Contrato;
  tarea: Tarea;
}

export interface DocumentoRevision {
  id: number;
  documentoId: number;
  revisionId: number;
  entregableId: number;
  observaciones: string;
  documento: Documento;
  contrato: Contrato;
  revisionTarea: Revision;
}



export interface Documento {
  id: number;
  documentoId: number;
  contratoId: number;
  observaciones: string;
  documento: DatosDocumento;
  contrato: Contrato;
  nombre: string;
  filename: string;
  entregableId?: number;
  updatedAt: Date;
  createdAt: Date;
}

export interface DatosDocumento {
  id: number;
  contratoId: number;
  nombre: string;
  filename: string;
  usuarioId: number;
}

export interface EstadoRevision {
  id: number;
  nombre: string;
  orden: number;
}

export interface PrioridadTarea {
  id: number;
  codigo: string;
  numOrder: number;
}

export interface Subestado {
  id: number;
  nombre: string;
  estadoId: number;
  esquemaEstados: string;
}

export interface Revision {
  id: number;
  tareaId: number;
  fechaTeoricaEjecucion: Date;
  fechaEjecucion?: any;
  observaciones?: any;
  justificado: boolean;
  alertaActivada: boolean;
  estadoId: number;
  subestadoId?: any;
  createdAt: Date;
  updatedAt: Date;
  contrato: Contrato;
  tarea: Tarea;
  estadoRevision: EstadoRevision;
  responsable: Usuario;
  contratante: Contratante;
  subestado?: any;
  historicoRevisionTarea?: any;
  enPlazo?: any;
  mesesRetraso?: any;
  tareasRetraso?: any;
  comentarios?: any;
  fechaValidada?: any;

  tipoContrato: TipoContrato;
  etiquetasArray: EtiquetasArray;
  miembrosArray: MiembrosArray;
}

export interface HistoricoRevisionTarea {
  id: number;
  revisionTareaId: number;
  accionId: number;
  fecha: Date;
  createdAt: Date;
  updatedAt: Date;
  usuarioId: number;
  datos: HistoricoRevisionTareaDatos;
  usuario: Usuario;
  documento: Documento;
}

export interface HistoricoRevisionTareaDatos {
  comentario?: string;
  estadoId?: number;
  subestadoId?: number;
}

export interface Tarea {
  id: number;
  contratoId: number;
  codigo: string;
  nombre: string;
  descripcion: string;
  elementoActuacionId?: number;
  origenTarea: string;
  afectaSeguridad: boolean;
  reglamentario: boolean;
  expresionCron: string;
  elementoActuacion: ElementoActuacion;
  contrato: Contrato;
  etiquetas: string[];
  asignaciones: string[];
  periodicidad: Periodicidad;
  frecuencia: Frecuencia;
  prioridadId: number;
  prioridad: PrioridadTarea;
  fechaInicio: number;
  fechaFinal: number;
  fechaObjetivo: number;
  tiempoRealizacion: number;
  observaciones: string;
  tipoEmisionInforme: string;
  responsableId?: number;
  estadoDefectoId: number;
  responsable: Usuario;
  miembroResponsable: Miembro;
  etiquetasArray: EtiquetasArray;
  miembrosArray: MiembrosArray;
}

export interface FormTarea {
  codigo: string;
  nombreTarea: string;
  descripcion: string;
  origenTarea: string;
  afectaSeguridad: boolean;
  reglamentario: boolean;
  expresionCron: string;
  periodicidad: string;
  estadoPorDefectoId: number;
}

export interface TareaCreate {
  contratoId: number;
  codigo: string;
  nombre: string;
  descripcion: string;
  elementoActuacionId: number;
  origenTarea?: any;
  afectaSeguridad: boolean;
  reglamentario: boolean;
  expresionCron: string;
}

export interface ElementoActuacion {
  id?: number;
  contratoId?: number;
  nombre?: string;
  contrato?: Contrato;
}

export interface DatosDashboard {
  contrato: Contrato;
  tarea: Tarea;
  revisionTarea: Revision;
}

export interface DatosDashboardStat {
  id: number;
  estado: string;
  count: number;
}

export interface Responsable {
  id: number;
  usuarioId: number;
  usuario: Usuario;
}

export interface EstadoFiltro {
  searchRequest: SearchRequest;
  tareasSeleccionadas: any[];
  periodicidadesSeleccionadas: any[];
  estadosRevisionSeleccionados: any[];
  clientesSeleccionados: any[];
  revisable: boolean;
  fechasSeleccionadas: any[];
  responsableSeleccionado: string;
  asignadoSeleccionado: string;
  mesesSeleccionados: number;
  tareasRetrasoSeleccionadas: number;
  etiquetasSeleccionadas: any[];
}

export interface SubestadoRequest {
  tipoContrato: string;
  estadoId: number;
}
